<template>
  <div class="settings q-px-md" id="app">
    <div class="q-my-md">

      <!-- LINK CATALOGO -->
      <q-expansion-item
        default-opened
        group="settingGroup"
        icon="language"
        label="Link Acesso Catálogo"
        header-class="bg-verde text-white"
        expand-icon-class="text-white"
      >
        <q-card class="bg-white bLBR">
          <q-card-section class="bg-verdeclaro">
            <div class="text-h6 text-bold bg-white">
              <div>
                <q-input id="textLink" class="q-pl-sm" v-model="linkCatalogo" type="text" readonly borderless />
                <!-- {{ linkCatalogo }} -->
              </div>
              <div>
                <q-icon
                  name="content_copy"
                  size="20px"
                  class="cursor-pointer"
                  @click="onCopyLinkCatalogo"
                />
              </div>
            </div>
          </q-card-section>
        </q-card>
      </q-expansion-item>

      <q-space class="q-mt-xs" />

      <!-- NOVOS ORÇAMENTOS -->
      <q-expansion-item
        group="settingGroup"
        icon="list_alt"
        label="Acompanhamento de Pedidos"
        header-class="bg-verde text-white"
        expand-icon-class="text-white"
      >
        <q-card class="bg-white bLBR">
          <q-card-section class="bg-verdeclaro">
            <q-table
              dense
              class="myTable"
              :rows="rows"
              :columns="cols"
              row-key="name"
              :rows-per-page-options="[10, 20, 50, 100, 99999]"
              rows-per-page-label="Linhas por página: "
              :filter="filter"
              no-results-label="Sem dados a serem mostrados"
              no-data-label="Sem dados a serem mostrados"
              @row-click="onEditRow"
            >
              <template v-slot:top-left>
                <q-input filled dense dark v-model="filter" placeholder="Pesquisar" style="width: 150px">
                  <template v-slot:append>
                    <q-icon name="search" />
                  </template>
                </q-input>
              </template>
              <!--
              <template v-slot:top-right>
                <q-btn
                  flat
                  rounded
                  style="background-color: var(--verdeclaro); color: white"
                  icon="file_download"
                  @click="exportTable(rows)"
                />
              </template>
              -->
            </q-table>
          </q-card-section>
        </q-card>
      </q-expansion-item>

      <q-space class="q-mt-xs" />

      <!-- SELEÇÃO DAS LIGAS -->
      <q-expansion-item
        group="settingGroup"
        icon="security"
        label="Selecionar Ligas"
        header-class="bg-verde text-white"
        expand-icon-class="text-white"
      >
        <q-card class="bg-white bLBR">
          <q-card-section>
            <div class="q-mb-md">
              <q-btn
                icon="save"
                label="Salvar"
                no-caps
                dense
                class="q-px-xl"
                style="background-color: var(--primary); color: white"
                @click="onSaveChooseLeagues()"
              />
            </div>
            <div class="containerLeague row">
              <div class="halfScreen">
                <div class="bg-red text-white">Ocultos</div>
                <div class="column items-center">
                  <div
                    v-for="disabled in disabledCategories"
                    :key="disabled"
                    class="styleChipDisabled cursor-pointer row justify-between"
                    @click="onAddLeague(disabled.name)"
                  >
                    {{ disabled.name }}
                    <div class="column justify-center">
                      <q-icon name="add_circle" size="sm" color="green" />
                    </div>
                  </div>
                </div>
              </div>
              <div class="halfScreen">
                <div class="bg-green text-white">Visíveis</div>
                <div class="column items-center">
                  <div
                    v-for="actived in activedCategories"
                    :key="actived"
                    class="styleChipActived row justify-between"
                    @click="onRemoveLeague(actived)"
                  >
                    <div class="column justify-center">
                      <q-icon name="remove_circle" size="sm" color="red" />
                    </div>
                    {{ actived }}
                  </div>
                </div>
              </div>
            </div>
          </q-card-section>
        </q-card>
      </q-expansion-item>

      <q-space class="q-mt-xs" />

      <!-- PERFIL DO CATÁLOGO -->
      <q-expansion-item
        group="settingGroup"
        icon="photo_album"
        label="Perfil do Catálogo"
        header-class="bg-verde text-white"
        expand-icon-class="text-white"
      >
        <q-card class="bg-white bLBR">
          <q-card-section>
            <div class="q-gutter-xl">
              <q-radio
                v-model="optCatalog"
                checked-icon="task_alt"
                unchecked-icon="panorama_fish_eye"
                val="sem"
                label="Catálogo SEM Preços" 
                @click="alterPerfilCatalog('without')"
              />
              <q-radio
                v-model="optCatalog"
                checked-icon="task_alt"
                unchecked-icon="panorama_fish_eye"
                val="com"
                label="Catálogo COM Preços*"
                @click="alterPerfilCatalog('with')"
              />
            </div>
          </q-card-section>

          <q-card-section v-show="optCatalog === 'com'">
            <div class="bA">
              <div class="col q-pt-lg q-px-lg justify-between">
                <q-select
                  class="q-my-sm"
                  v-model="folders"
                  outlined
                  :options="optFolders"
                  label="Selecionar Categoria"
                />
                <q-btn
                  icon="check"
                  label="IR"
                  @click="chooseFolder"
                  style="width: 100px; background-color: var(--primary); color: white"
                />
              </div>

              <div class="text-red text-caption text-bold text-italic text-right q-px-lg q-mb-lg">* Itens SEM PREÇO não serão exibidos no Catálogo</div>

              <div class="q-gutter-x-md q-my-sm q-mx-lg row justify-start" v-show="itemsStorage">
                <q-btn
                  style="background-color: var(--primary); color: white"
                  no-caps
                  label="Gravar Alterações"
                  @click="onBurnChanges()"
                />
              </div>

              <div class="col q-mx-lg">
                <div v-for="(item, idx) in itemsStorage" :key="idx">
                  <div
                    class="
                      bCards
                      q-mx-sm
                      q-my-sm
                      q-pa-sm
                      justify-between
                      row
                      full-width
                    "
                    :class="{'bg-green-1': item.newProduct, 'bg-white': !item.newProduct}"
                  >
                    <div :class="{'row justify-center full-width': isMobile}">
                      <q-img
                        class="imgCard"
                        :src="item.url"
                        :ratio="1"
                        spinner-color="primary"
                        spinner-size="82px"
                        width="150px"
                        height="150px"
                        loading="lazy"
                      />
                    </div>
                    <div class="column justify-center" :class="{'full-width': isMobile}">
                      <div class="text-h6 text-bold text-center">{{ item.title }}</div>
                      <div class="text-subtitle1 text-center">{{ item.subTitle }}</div>
                    </div>
                    <div class="q-gutter-y-sm" :class="{'row justify-center': isMobile}">
                      <q-input
                        v-model="item.priceSales"
                        outlined
                        dense
                        type="text"
                        label="Preço de Venda"
                      />
                      <q-input
                        v-model="item.pricePromotional"
                        outlined
                        dense
                        type="text"
                        label="Preço Promocional"
                      />
                      <div>
                        <q-toggle
                          v-model="item.freeShipping"
                          label="Frete Grátis"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="q-gutter-x-md q-my-sm q-mx-lg row justify-start" v-show="itemsStorage">
                <q-btn
                  style="background-color: var(--primary); color: white"
                  no-caps
                  label="Gravar Alterações"
                  @click="onBurnChanges()"
                />
                <q-btn
                  style="background-color: var(--primary); color: white"
                  no-caps
                  label="Ir para Topo"
                  @click="onGoToTop()"
                />
              </div>
            </div>
          </q-card-section>
        </q-card>
      </q-expansion-item>

      <q-space class="q-mt-xs" />

      <!-- CADASTRAR WHATSAPP -->
      <q-expansion-item
        group="settingGroup"
        icon="fab fa-whatsapp"
        label="Cadastrar Whatsapp"
        header-class="bg-verde text-white"
        expand-icon-class="text-white"
      >
        <q-card class="bg-white bLBR">
          <q-card-section>
            <div class="respRowForm">
              <!-- NÚMERO WHATSAPP -->
              <q-input
                dense
                outlined
                class="twoFields"
                v-model="numWhats"
                type="text"
                label="Número Whatsapp"
              />
                <!-- :rules="[
                  val => val.length >= 10 || 'Favor informar o número com no mínimo 10 dígitos',
                  val => val[0] !== '0' || 'Utilizar o DDD com apenas 2 dígitos (suprimir o zero)',
                ]" -->

              <!-- ORIENTAÇÕES -->
              <q-input
                dense
                outlined
                readonly
                class="twoFields"
                label="Exemplo de números"
                v-model="exampleWhats"
              />
            </div>

            <div>
              <div class="text-red text-bold text-italic text-subtitle1">
                Regras para cadastrar número:
              </div>
              <q-list class="row" bordered>
                <q-item>
                  <q-item-section>
                    <q-item-label class="text-bold">Regra do DDD</q-item-label>
                    <q-item-label caption>O DDD deve conter apenas 2 dígitos, portanto devemos suprimir o 0 (zero) inicial</q-item-label>
                  </q-item-section>

                  <q-separator spaced inset />

                  <q-item-section>
                    <q-item-label class="text-bold">Regras do Número</q-item-label>
                    <q-item-label caption>Se celular sempre incluir o 9 inicial (número deve conter 9 dígitos), se fixo deve conter 8 dígitos. Nunca utilize o hífen para separar o número. Mesma regra se aplica à números internacionais, lembrando que nestes OBRIGATORIAMENTE devemos informar o DDI (Ex.: EUA +1, Espanha +34)</q-item-label>
                  </q-item-section>
                </q-item>
              </q-list>
            </div>

            <!-- MENSAGEM PADRÃO -->
            <q-input
              outlined
              v-model="msgWhats"
              type="textarea"
              label="Texto padrão"
              class="q-mt-lg"
            />
          </q-card-section>
          <q-card-section>
            <q-btn no-caps label="Cadastrar Whatsapp" @click="onRegisterWhats" style="background-color: var(--primary); color: white;" />
          </q-card-section>
        </q-card>
      </q-expansion-item>

      <q-space class="q-mt-xs" />

      <!-- ALTERAÇÃO DE SENHA -->
      <q-expansion-item
        group="settingGroup"
        icon="fas fa-key"
        label="Alterar Senha"
        header-class="bg-verde text-white"
        expand-icon-class="text-white"
      >
        <q-card class="bg-white bLBR">
          <q-card-section class="respRowForm">
            <!-- NOVA SENHA -->
            <q-input
              dense
              outlined
              class="twoFields"
              v-model="newPsw"
              :type="isNewPwd ? 'password' : 'text'"
              label="Nova Senha"
              :rules="[val => val.length >= 6 || 'Favor informar senha de no mínimo 6 dígitos']"
            >
              <template v-slot:append>
                <q-icon
                  :name="isNewPwd ? 'visibility' : 'visibility_off'"
                  @click="isNewPwd = !isNewPwd"
                />
              </template>
            </q-input>
            <!-- CONFIRMAR NOVA SENHA -->
            <q-input
              dense
              outlined
              class="twoFields"
              v-model="rNewPsw"
              :type="isRNewPwd ? 'password' : 'text'"
              label="Confirmar Senha"
              :rules="[val => val.length >= 6 || 'Favor informar senha de no mínimo 6 dígitos']"
            >
              <template v-slot:append>
                <q-icon
                  :name="isRNewPwd ? 'visibility' : 'visibility_off'"
                  @click="isRNewPwd = !isRNewPwd"
                />
              </template>
            </q-input>
          </q-card-section>
          <q-card-section>
            <q-btn no-caps label="Alterar Senha" @click="onChangePsw" style="background-color: var(--primary); color: white;" />
          </q-card-section>
        </q-card>
      </q-expansion-item>

      <q-space class="q-mt-xs" />

      <!-- ESCOLHA DE UM TEMA -->
      <q-expansion-item
        group="settingGroup"
        icon="fas fa-palette"
        label="Escolher Tema"
        header-class="bg-verde text-white"
        expand-icon-class="text-white"
      >
        <q-card class="bg-white bLBR">
          <q-card-section class="respRowForm q-gutter-sm">
            <div class="respRowForm row justify-around">
              <q-radio
                v-for="opt in optTheme"
                :key="opt"
                v-model="theme"
                :val="opt.value"
                :label="opt.label"
                @click="onTheme(opt.value)"
              />
            </div>
          </q-card-section>
          <q-card-section>
            <q-btn no-caps label="Alterar Tema" @click="onChangeTheme" style="background-color: var(--primary); color: white;" />
          </q-card-section>
        </q-card>
      </q-expansion-item>

      <q-space class="q-mt-xs" />

      <!-- SOBRE -->
      <q-expansion-item
        group="settingGroup"
        icon="fas fa-info"
        label="Sobre"
        header-class="bg-verde text-white"
        expand-icon-class="text-white"
      >
        <q-card class="bg-white bLBR">
          <q-card-section class="bg-verdeclaro">
            <q-img
              src="../../assets/LogoCatalogo.png"
              width="18%"
            />
            <div class="text-subtitle2 text-italic text-bold">
              {{ 'Versão: ' + version }}
            </div>
            <div class="text-subtitle1 q-my-lg">
              O melhor e mais completo Catálogo para artigos esportivos.
              <br>
              Tudo ao seu alcance buscando sempre atender as
              <br>
              necessidades dos clientes e facilitar as rotinas diárias.
            </div>
            
          </q-card-section>
        </q-card>
      </q-expansion-item>

      <q-space class="q-mt-xs" />
    </div>

    <!-- MODAL PARA PRECIFICAÇÃO DOS ORÇAMENTOS -->
    <q-dialog v-model="modalPreSale" full-width persistent>
      <div class="bg-white q-pa-lg q-gutter-y-sm">
        <div class="title text-h5 text-bold text-center text-verde q-mb-md">
          DETALHES DO ORÇAMENTO
        </div>

        <q-separator spaced size="5px" style="background-color: var(--primary)" />
        
        <q-scroll-area style="width: 100%; height: 40vh;">
          <div class="respRowForm q-gutter-y-sm q-mt-sm">
            <q-input class="oneFields" outlined v-model="client" type="text" label="Nome do Cliente" />
          </div>
          <div class="respRowForm q-gutter-y-sm q-mt-sm">
            <q-input class="threeFields"  outlined v-model="phone" type="text" label="Contato" />
            <q-input class="threeFields"  outlined v-model="issueDate" type="date" label="Data do Orçamento" />
            <q-select class="threeFields" outlined v-model="status" :options="['PENDENTE', 'ENVIADO AO CLIENTE', 'FINALIZADO', 'CANCELADO']" label="Status" />
          </div>
          <div class="respRowForm q-gutter-y-sm q-mt-sm">
            <q-input class="oneFields" outlined v-model="address" type="text" label="Endereço do Cliente" />
          </div>
          <div class="respRowForm q-gutter-y-sm q-mt-sm">
            <q-input
              class="oneFields"
              outlined
              v-model="observ"
              type="textarea"
              label="Observações"
              placeholder="
              Exemplo:
                Forma de Pagamento: À Vista (Dinheiro ou PIX) ou em até 3x sem juros no cartão
                Validade: Este orçamento tem validade de 10 dias após sua emissão
                Previsão de Entrega: 15 dias úteis (após confirmação do pagamento)
              "
            />
          </div>
          <div class="q-gutter-y-sm q-mt-md">
            <div
              class="row q-gutter-x-sm"
              v-for="(item, index) in items"
              :key="index"
            >
              <!-- VERSÃO DESKTOP -->
              <div v-if="!isMobile" class="row items-center q-gutter-x-sm justify-between q-mr-md">
                <q-img :src="item.url" width="80px" height="80px" />
                <q-input class="col-2" dense v-model="item.team" type="text" label="Time" />
                <q-input class="col-1" dense v-model="item.model" type="text" label="Modelo" />
                <q-input class="col-1" dense v-model="item.gender" type="text" label="Sexo" />
                <q-input class="col-2" dense v-model="item.custom" type="text" label="Personalização" />
                <q-input class="col-1" dense v-model="item.size" type="text" label="Tamanho" />
                <q-input class="col-1" dense v-model="item.qtdade" type="number" label="Quantidade" />
                <q-input class="col-1" dense v-model="item.cost" type="number" label="Custo" />
                <q-input class="col-1" dense v-model="item.price" type="number" label="Valor" />
              </div>
              <!-- VERSÃO MOBILE -->
              <div v-else class="row items-center q-mb-md q-mr-md justify-center">
                <q-img :src="item.url" width="80px" height="80px" />
                <q-input class="col-10" dense v-model="item.team" type="text" label="Time" />
                <q-input class="col-10" dense v-model="item.model" type="text" label="Modelo" />
                <q-input class="col-10" dense v-model="item.gender" type="text" label="Sexo" />
                <q-input class="col-10" dense v-model="item.custom" type="text" label="Personalização" />
                <q-input class="col-10" dense v-model="item.size" type="text" label="Tamanho" />
                <q-input class="col-10" dense v-model="item.qtdade" type="number" label="Quantidade" />
                <q-input class="col-10" dense v-model="item.cost" type="number" label="Custo" />
                <q-input class="col-10" dense v-model="item.price" type="number" label="Valor" />
              </div>
            </div>
          </div>
        </q-scroll-area>

        <div class="btns row justify-center q-pt-md q-gutter-md">
          <q-btn outline no-caps color="negative" label="Cancelar" @click="onCancel" />
          <q-btn outline no-caps color="negative" label="Excluir Orçamento" @click="onDelete" />
          <q-btn outline no-caps color="green" label="Salvar Orçamento" @click="onSave" />
          <q-btn outline no-caps color="warning" label="Gerar Comprovante" @click="onOpenComprovante" />
          <!-- <q-btn outline no-caps color="info" label="Gravar Venda" @click="onSale" /> -->
        </div>
      </div>
    </q-dialog>

    <!-- MODAL DO COMPROVANTE -->
    <q-dialog v-model="modalComprovante" persistent full-width>
      <div class="bg-white q-pa-lg q-gutter-y-sm">
        <div class="title text-h5 text-bold text-center text-verde q-mb-md">
          EMITIR ORÇAMENTO
        </div>

        <q-separator spaced size="5px" style="background-color: var(--primary)" />

        <q-scroll-area class="bg-grey" style="width: 100%; height: 60vh;">
          <div class="q-mx-xl q-my-lg">
            <div class="bg-white orcamento" ref="htmlPDF" id="newPDF">
              <div class="row justify-center items-center q-mx-xl q-py-md">
                <div class="col-2 row justify-center">
                  <q-img :src="logoIMG" height="80px" />
                </div>
                <div class="col-4 row justify-center text-h4 text-bold text-italic">
                  {{ enterprise }}
                </div>
              </div>
              <div>
                <div class="gradeTable row justify-between q-mx-xl">
                  <div class="row col-4">
                    <div class="col-6 bg-verde  text-white q-pl-md">Nº Orçamento</div>
                    <div class="col-6 q-pl-md">{{ editRow.recno }}</div>
                  </div>
                  <div class="row col-4">
                    <div class="col-6 bg-verde text-white q-pl-md">Data Emissão</div>
                    <div class="col-6 q-pl-md">{{ dataEmissao }}</div>
                  </div>
                  <div class="row col-4">
                    <div class="col-6 bg-verde text-white q-pl-md">Contato</div>
                    <div class="col-6 q-pl-md">{{ phone }}</div>
                  </div>
                </div>
                <div class="gradeTable row q-mx-xl">
                  <div class="col-2 bg-verde text-white q-pl-md">Nome Cliente</div>
                  <div class="col-10 q-pl-md">{{ client }}</div>
                </div>
                <div class="gradeTable row q-mx-xl">
                  <div class="col-2 bg-verde text-white q-pl-md">Endereço</div>
                  <div class="col-10 q-pl-md">{{ address }}</div>
                </div>
              </div>
              
              <div class="text-h5 text-bold text-center q-my-lg">Orçamento de Produtos</div>

              <div class="q-pb-xl">
                <div class="row justify-center items-center">
                  <div class="gradeTableL col-2 bg-verde text-center text-white text-bold">Ilustração</div>
                  <div class="gradeTableC col-1 bg-verde text-center text-white text-bold">Quantidade</div>
                  <div class="gradeTableC col-4 bg-verde text-center text-white text-bold">Descrição</div>
                  <div class="gradeTableC col-2 bg-verde text-center text-white text-bold">Unitário</div>
                  <div class="gradeTableR col-2 bg-verde text-center text-white text-bold">SubTotal</div>
                </div>
                <div class="row justify-center" v-for="(row, index) in editRow.items" :key="index">
                  <div class="gradeTableL row items-center justify-center col-2" style="height: 90px"><q-img :src="row.url" width="80px" height="80px" style="border-radius: 20px;" /></div>
                  <div class="gradeTableC row items-center justify-center col-1" style="height: 90px">{{ row.qtdade }}</div>
                  <div class="gradeTableC row items-center justify-center col-4" style="height: 90px">{{ `${row.team} ${row.model} (${row.size} - ${row.custom})` }}</div>
                  <div class="gradeTableC row items-center justify-center col-2" style="height: 90px">{{ `R$ ${parseFloat(parseFloat(row.price) / parseInt(row.qtdade)).toFixed(2)}` }}</div>
                  <div class="gradeTableR row items-center justify-center col-2" style="height: 90px">{{ `R$ ${parseFloat(row.price).toFixed(2)}` }}</div>
                </div>
                <div class="row justify-center full-width text-subtitle1 text-bold">
                  <div class="col-11 row gradeTable">
                    <div class="col-6 bg-verde text-center text-white q-pl-md">Valor Total</div>
                    <div class="col-6 text-center q-pl-md">{{ `R$ ${totalGeral}` }}</div>
                  </div>
                </div>
                <div>
                  <div class="q-mt-md" >
                    <div class="bg-verde text-white text-bold text-center q-mx-xl">Observações</div>
                    <q-input outlined class="q-mx-xl" v-model="observ" type="textarea" autogrow />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </q-scroll-area>

        <div class="btns row justify-center q-pt-md">
          <q-btn outline no-caps color="negative" label="Fechar" @click="modalComprovante = false" />
          <div class="q-mx-md" />
          <!-- <button @click="onSaveComprovante" >Guardar Comprovante</button> -->
          <q-btn outline no-caps color="green" label="Guardar Comprovante" @click="onSaveComprovante" />
        </div>
      </div>
    </q-dialog>

  </div>
</template>

<script>
import { supabase } from '../../supabase';
import { Dialog, Loading, QSpinnerHourglass } from 'quasar';
import { mapActions } from 'vuex';
import { defaultColor } from '../../helpers/defaultColor';
import { version } from '../../../package.json';
import Slug from '../../helpers/Slug';
import DateFormat from '../../helpers/DateFormat';
import removeExtensions from '../../helpers/removeExtensions';
import * as htmlToImage from 'html-to-image';

export default {
  name: 'app',
  data() {
    return {
      version: version,
      enterprise: localStorage.getItem('enterprise'),
      isNewPwd: true,
      isRNewPwd: true,
      newPsw: null,
      rNewPsw: null,
      theme: null,
      categorys: [],
      activedCategories: [],
      disabledCategories: [],
      linkCatalogo: 'https://',
      recno: null,
      rows: [],
      cols: [
        { name: 'recno',   align: 'center', label: 'ID',        field: 'recno',   sortable: true },
        { name: 'client',  align: 'center', label: 'Cliente',   field: 'client',  sortable: true },
        { name: 'phone',   align: 'center', label: 'Telefone',  field: 'phone',   sortable: true },
        { name: 'status',  align: 'center', label: 'Status',    field: 'status',  sortable: true },
        { name: 'dt',      align: 'center', label: 'Data',      field: 'dt',      sortable: true,
          format: val => DateFormat(String(val).replaceAll('-', ''))
        },
      ],
      filter: null,
      optTheme: [
        { label: 'Vermelho Claro',  value: 'red_l'    },
        { label: 'Vermelho Escuro', value: 'red_d'    },
        { label: 'Rosa Claro',      value: 'pink_l'   },
        { label: 'Rosa Escuro',     value: 'pink_d'   },
        { label: 'Roxo',            value: 'purple'   },
        { label: 'Lilas',           value: 'lilac'    },
        { label: 'Azul Claro',      value: 'blue_l'   },
        { label: 'Azul Escuro',     value: 'blue_d'   },
        { label: 'Verde Claro',     value: 'green_l'  },
        { label: 'Verde Escuro',    value: 'green_d'  },
        { label: 'Dourado',         value: 'gold'     },
        { label: 'Amarelo Claro',   value: 'yellow_l' },
        { label: 'Amarelo Escuro',  value: 'yellow_d' },
        { label: 'Laranja',         value: 'orange'   },
        { label: 'Marron Claro',    value: 'brown_l'  },
        { label: 'Marron Escuro',   value: 'brown_d'  },
        { label: 'Cinza Claro',     value: 'grey_l'   },
        { label: 'Cinza Escuro',    value: 'default'  },
        { label: 'Preto',           value: 'black'    },
      ],
      numWhats: null,
      msgWhats: null,
      exampleWhats: '11987654321 ou 1133210987',
      errorNumWhats: null,

      //~> PARA O MODAL
      modalPreSale: false,
      editRow: null,
      recnoTable: null,
      client: null,
      phone: null,
      issueDate: null,
      status: null,
      items: null,
      address: null,
      cost: 0,
      price: 0,
      comprovante: false,
      observ: null,

      //~> PARA O COMPROVANTE
      modalComprovante: false,
      logoIMG: null,
      dataEmissao: null,
      totalGeral: 0,

      //~> PARA PRECIFICAR CATÁLOGO
      perfilCatalogDB: null,
      optCatalog: 'sem',
      optFolders: [
        'Babybody', 'Camisas NFL', 'Casual', 'Chuteiras/Campo', 'Chuteiras/Salao', 'Chuteiras/Society', 'Chuteiras/Trava Mista',
        'Conjuntos/Passeio', 'Conjuntos/Treino', 'Corta Vento', 'Femininas', 'Formula 1', 'Kit Infantil',
        'Luvas de Goleiro', 'Meias', 'Moto GP', 'Polo', 'Regatas Futebol', 'Regatas NBA', 'Retro', 'Short/Jogo', 'Short/NBA', 'Short/Treino',
        'Tenis/Adidas', 'Tenis/Balenciaga', 'Tenis/Gucci', 'Tenis/New Balance', 'Tenis/Nike',
        'Versao Jogador/Brasileirao', 'Versao Jogador/Bundesliga', 'Versao Jogador/La Liga', 'Versao Jogador/Liga MX', 'Versao Jogador/Ligue 1', 'Versao Jogador/MLS',
        'Versao Jogador/Outras Ligas', 'Versao Jogador/Premier League',  'Versao Jogador/SAF',  'Versao Jogador/Selecoes',  'Versao Jogador/Serie A',
        'Versao Torcedor/Brasileirao', 'Versao Torcedor/Bundesliga', 'Versao Torcedor/La Liga', 'Versao Torcedor/Liga MX', 'Versao Torcedor/Ligue 1', 'Versao Torcedor/MLS',
        'Versao Torcedor/Outras Ligas', 'Versao Torcedor/Premier League',  'Versao Torcedor/SAF',  'Versao Torcedor/Selecoes',  'Versao Torcedor/Serie A',
      ],
      folders: null,
      pricedItems: null,
      priceSell: [],
      itemsStorage: null,
      itemsDatabase: null,
      summerItems: null
    }
  },
  computed: {
    isMobile() {
      return (this.$q.screen.sm || this.$q.screen.xs);
    },
  },
  methods: {
    ...mapActions('Functions', [ 'rpc', 'burnDatas' ]),
    ...mapActions('Querys', [ 'insertData', 'updateData', 'getDataWithoutFilters', 'getDataWithFilters' ]),
    async alterPerfilCatalog(oldPerfil) {
      var newPerfil;

      Dialog.create({
        title: 'Fature Catálogo',
        message: 'Deseja realmente mudar o Perfil do seu Catálogo?',
        ok: 'Sim',
        cancel: 'Não'
      }).onOk(async () => {
        if(this.optCatalog === 'com') {
          newPerfil = 'with';
        } else {
          newPerfil = 'without';
        }

        await supabase
          .from('GE_SETTINGS')
            .update({'GE_PRICEINCATALOG': newPerfil})
              .match({'GE_ENTERPRISE': this.enterprise})
      }).onCancel(() => {
        if(oldPerfil === 'with') {
          this.optCatalog = 'sem';
        } else {
          this.optCatalog = 'com';
        }
      })

    },
    async onBurnChanges() {

      const burn = await this.burnDatas({
        "items": this.itemsStorage,
        "enterprise": this.enterprise,
        "folder": this.folders
      });

      if(burn[0].RECNO > 0) {
        Dialog.create({
          title: 'Fature Catálogo',
          message: 'Alterações gravadas com sucesso!'
        })
      } 

    },
    onGoToTop() {
      window.scrollTo(0,0);
    },
    async chooseFolder() {
      await this.onSummerItems();
    },
    async onSummerItems() {
      await this.productsStorage()
      await this.productsDatabase()
      
      const arrItems = []
      this.itemsStorage.forEach(item => {
        const element = this.itemsDatabase.find(el => el.name === item.name);

        if(JSON.stringify(element) !== undefined) {
          arrItems.push(element);
        } else {
          arrItems.push(item);
        }
      })

      console.log(arrItems);
      this.itemsStorage = arrItems;
    },
    async productsDatabase() {
      const dbProd = await supabase
        .from('GE_SETTINGS')
        .select(String(this.folders).toLowerCase().replace('/', ''))
        .eq('GE_ENTERPRISE', this.enterprise)

      if(dbProd?.data[0]) {
        switch (this.folders) {
          case 'Babybody':
            this.itemsDatabase = dbProd.data[0]?.babybody
            break;
          case 'Camisas NFL':
            this.itemsDatabase = dbProd.data[0]?.camisasnfl
            break;
          case 'Casual':
            this.itemsDatabase = dbProd.data[0]?.casual
            break;
          case 'Chuteiras/Campo':
            this.itemsDatabase = dbProd.data[0]?.chuteirascampo
            break;
          case 'Chuteiras/Salao':
            this.itemsDatabase = dbProd.data[0]?.chuteirassalao
            break;
          case 'Chuteiras/Society':
            this.itemsDatabase = dbProd.data[0]?.chuteirassociety
            break;
          case 'Chuteiras/Trava Mista':
            this.itemsDatabase = dbProd.data[0]?.chuteirastravamista
            break;
          case 'Conjuntos/Passeio':
            this.itemsDatabase = dbProd.data[0]?.conjuntospasseio
            break;
          case 'Conjuntos/Treino':
            this.itemsDatabase = dbProd.data[0]?.conjuntostreino
            break;
          case 'Corta Vento':
            this.itemsDatabase = dbProd.data[0]?.cortavento
            break;
          case 'Femininas':
            this.itemsDatabase = dbProd.data[0]?.femininas
            break;
          case 'Formula 1':
            this.itemsDatabase = dbProd.data[0]?.formula1
            break;
          case 'Kit Infantil':
            this.itemsDatabase = dbProd.data[0]?.kitinfantil
            break;
          case 'Luvas de Goleiro':
            this.itemsDatabase = dbProd.data[0]?.luvasdegoleiro
            break;
          case 'Meias':
            this.itemsDatabase = dbProd.data[0]?.meias
            break;
          case 'Moto GP':
            this.itemsDatabase = dbProd.data[0]?.motogp
            break;
          case 'Polo':
            this.itemsDatabase = dbProd.data[0]?.polo
            break;
          case 'Regatas Futebol':
            this.itemsDatabase = dbProd.data[0]?.regatasfutebol
            break;
          case 'Regatas NBA':
            this.itemsDatabase = dbProd.data[0]?.regatasnba
            break;
          case 'Retro':
            this.itemsDatabase = dbProd.data[0]?.retro
            break;
          case 'Short/Jogo':
            this.itemsDatabase = dbProd.data[0]?.shortjogo
            break;
          case 'Short/NBA':
            this.itemsDatabase = dbProd.data[0]?.shortnba
            break;
          case 'Short/Treino':
            this.itemsDatabase = dbProd.data[0]?.shorttreino
            break;
          case 'Tenis/Adidas':
            this.itemsDatabase = dbProd.data[0]?.tenisadidas
            break;
          case 'Tenis/Balenciaga':
            this.itemsDatabase = dbProd.data[0]?.tenisbalenciaga
            break;
          case 'Tenis/Gucci':
            this.itemsDatabase = dbProd.data[0]?.tenisgucci
            break;
          case 'Tenis/New Balance':
            this.itemsDatabase = dbProd.data[0]?.tenisnewbalance
            break;
          case 'Tenis/Nike':
            this.itemsDatabase = dbProd.data[0]?.tenisnike
            break;
          case 'Versao Jogador/Brasileirao':
            this.itemsDatabase = dbProd.data[0]?.versaojogadorbrasileirao
            break;
          case 'Versao Jogador/Bundesliga':
            this.itemsDatabase = dbProd.data[0]?.versaojogadorbundesliga
            break;
          case 'Versao Jogador/La Liga':
            this.itemsDatabase = dbProd.data[0]?.versaojogadorlaliga
            break;
          case 'Versao Jogador/Liga MX':
            this.itemsDatabase = dbProd.data[0]?.versaojogadorligamx
            break;
          case 'Versao Jogador/Ligue 1':
            this.itemsDatabase = dbProd.data[0]?.versaojogadorligue1
            break;
          case 'Versao Jogador/MLS':
            this.itemsDatabase = dbProd.data[0]?.versaojogadormls
            break;
          case 'Versao Jogador/Outras Ligas':
            this.itemsDatabase = dbProd.data[0]?.versaojogadoroutrasligas
            break;
          case 'Versao Jogador/Premier League':
            this.itemsDatabase = dbProd.data[0]?.versaojogadorpremierleague
            break;
          case 'Versao Jogador/SAF':
            this.itemsDatabase = dbProd.data[0]?.versaojogadorsaf
            break;
          case 'Versao Jogador/Selecoes':
            this.itemsDatabase = dbProd.data[0]?.versaojogadorselecoes
            break;
          case 'Versao Jogador/Serie A':
            this.itemsDatabase = dbProd.data[0]?.versaojogadorseriea
            break;
          case 'Versao Torcedor/Brasileirao':
            this.itemsDatabase = dbProd.data[0]?.versaotorcedorbrasileirao
            break;
          case 'Versao Torcedor/Bundesliga':
            this.itemsDatabase = dbProd.data[0]?.versaotorcedorbundesliga
            break;
          case 'Versao Torcedor/La Liga':
            this.itemsDatabase = dbProd.data[0]?.versaotorcedorlaliga
            break;
          case 'Versao Torcedor/Liga MX':
            this.itemsDatabase = dbProd.data[0]?.versaotorcedorligamx
            break;
          case 'Versao Torcedor/Ligue 1':
            this.itemsDatabase = dbProd.data[0]?.versaotorcedorligue1
            break;
          case 'Versao Torcedor/MLS':
            this.itemsDatabase = dbProd.data[0]?.versaotorcedormls
            break;
          case 'Versao Torcedor/Outras Ligas':
            this.itemsDatabase = dbProd.data[0]?.versaotorcedoroutrasligas
            break;
          case 'Versao Torcedor/Premier League':
            this.itemsDatabase = dbProd.data[0]?.versaotorcedorpremierleague
            break;
          case 'Versao Torcedor/SAF':
            this.itemsDatabase = dbProd.data[0]?.versaotorcedorsaf
            break;
          case 'Versao Torcedor/Selecoes':
            this.itemsDatabase = dbProd.data[0]?.versaotorcedorselecoes
            break;
          case 'Versao Torcedor/Serie A':
            this.itemsDatabase = dbProd.data[0]?.versaotorcedorseriea
            break;
          default:
            break;
        }
      }
    },
    async productsStorage() {
      var arrImages = []
      const { data, error } = await supabase
        .storage
        .from('catalogo')
        .list(this.folders, {
          limit: 9999
        })

      if(data) {
        data.forEach(async file => {
          const folderFile = `${this.folders}/${file.name}`;

          const { publicURL } = supabase
            .storage
            .from('catalogo')
            .getPublicUrl(folderFile)

          const name = String(file.name).split('_');
          const title = name[0]
          const subTitle = removeExtensions(name[1]).name

          arrImages.push({
            ...file,
            url: publicURL,
            title: title,
            subTitle: subTitle,
            freeShipping: false,
            pricePromotional: 0,
            priceSales: 0,
            newProduct: true
          })
        })

        this.itemsStorage = arrImages;
      }

      if(error)
        Dialog.create({
          title: 'Fature Catálogo',
          message: `Erro ao retornar os produtos da Catagoria: ${this.folders}`
        })
    },
    async onChangePsw() { //~> OK
      if(
        this.newPsw == null || this.newPsw == '' || this.newPsw.length < 6 ||
        this.rNewPsw == null || this.rNewPsw == '' || this.rNewPsw.length < 6
      ) {
        Dialog.create({
          title: 'Fature Catálogo',
          message: 'Favor preencher corretamente todos os campos!'
        })
      } else if (
        this.newPsw !== this.rNewPsw
      ) {
        Dialog.create({
          title: 'Fature Catálogo',
          message: 'Favor rever senhas informadas, "Nova Senha" e "Confirmar Senha" devem ser iguais.'
        })
      } else {
        const { user, error } = await supabase
          .auth
          .update({
            password: this.newPsw
          })

        if (error) { Dialog.create({ title: 'Fature Catálogo', message: error.message}) }
        if (user) { Dialog.create({ title: 'Fature Catálogo', message: 'Senha alterada com sucesso!'}) }
      }

    },
    onTheme(value) { //~> OK

      let bodyStyles = document.body.style;
      switch (value) {
        case 'default':
          bodyStyles.setProperty('--primaryLight', '#718F9C')
          bodyStyles.setProperty('--primary', '#556D77')
          bodyStyles.setProperty('--primaryDark', '#314148')
          break;
        case 'grey_l':
          bodyStyles.setProperty('--primaryLight', '#BDCACF')
          bodyStyles.setProperty('--primary', '#89A1AC')
          bodyStyles.setProperty('--primaryDark', '#65818C')
          break;
        case 'black':
          bodyStyles.setProperty('--primaryLight', '#363636')
          bodyStyles.setProperty('--primary', '#1B1B1B')
          bodyStyles.setProperty('--primaryDark', '#000000')
          break;
        case 'brown_l':
          bodyStyles.setProperty('--primaryLight', '#B29A92')
          bodyStyles.setProperty('--primary', '#A08277')
          bodyStyles.setProperty('--primaryDark', '#8E695B')
          break;
        case 'brown_d':
          bodyStyles.setProperty('--primaryLight', '#8E695B')
          bodyStyles.setProperty('--primary', '#725247')
          bodyStyles.setProperty('--primaryDark', '#51352F')
          break;
        case 'orange':
          bodyStyles.setProperty('--primaryLight', '#FF8617')
          bodyStyles.setProperty('--primary', '#F16910')
          bodyStyles.setProperty('--primaryDark', '#AD4500')
          break;
        case 'yellow_l':
          bodyStyles.setProperty('--primaryLight', '#FFEE58')
          bodyStyles.setProperty('--primary', '#FFED2E')
          bodyStyles.setProperty('--primaryDark', '#E0B415')
          break;
        case 'yellow_d':
          bodyStyles.setProperty('--primaryLight', '#FFCC28')
          bodyStyles.setProperty('--primary', '#FFB120')
          bodyStyles.setProperty('--primaryDark', '#FFA21D')
          break;
        case 'gold':
          bodyStyles.setProperty('--primaryLight', '#BF9A07')
          bodyStyles.setProperty('--primary', '#927502')
          bodyStyles.setProperty('--primaryDark', '#715D10')
          break;
        case 'green_l':
          bodyStyles.setProperty('--primaryLight', '#68E035')
          bodyStyles.setProperty('--primary', '#389F0B')
          bodyStyles.setProperty('--primaryDark', '#287308')
          break;
        case 'green_d':
          bodyStyles.setProperty('--primaryLight', '#017D28')
          bodyStyles.setProperty('--primary', '#05561F')
          bodyStyles.setProperty('--primaryDark', '#033814')
          break;
        case 'blue_l':
          bodyStyles.setProperty('--primaryLight', '#30DBF1')
          bodyStyles.setProperty('--primary', '#00C3DB')
          bodyStyles.setProperty('--primaryDark', '#088495')
          break;
        case 'blue_d':
          bodyStyles.setProperty('--primaryLight', '#0022CC')
          bodyStyles.setProperty('--primary', '#00157D')
          bodyStyles.setProperty('--primaryDark', '#000D50')
          break;
        case 'lilac':
          bodyStyles.setProperty('--primaryLight', '#9433D0')
          bodyStyles.setProperty('--primary', '#740DB4')
          bodyStyles.setProperty('--primaryDark', '#540785')
          break;
        case 'purple':
          bodyStyles.setProperty('--primaryLight', '#6428D4')
          bodyStyles.setProperty('--primary', '#4A0CBD')
          bodyStyles.setProperty('--primaryDark', '#30067D')
          break;
        case 'pink_l':
          bodyStyles.setProperty('--primaryLight', '#FBB0C8')
          bodyStyles.setProperty('--primary', '#FF97B8')
          bodyStyles.setProperty('--primaryDark', '#F97BA2')
          break;
        case 'pink_d':
          bodyStyles.setProperty('--primaryLight', '#F76794')
          bodyStyles.setProperty('--primary', '#F43B75')
          bodyStyles.setProperty('--primaryDark', '#951644')
          break;
        case 'red_l':
          bodyStyles.setProperty('--primaryLight', '#EF8A86')
          bodyStyles.setProperty('--primary', '#F86C63')
          bodyStyles.setProperty('--primaryDark', '#FD5D47')
          break;
        case 'red_d':
          bodyStyles.setProperty('--primaryLight', '#F36659')
          bodyStyles.setProperty('--primary', '#C93226')
          bodyStyles.setProperty('--primaryDark', '#6D1212')
          break;
        default:
          break;
      }

    },
    async onChangeTheme() { //~> OK
      let recno = localStorage.getItem('recnoClient');
      let theme = this.theme;

      await this.updateData({
        table: 'GE_PERFIL_USERS',
        fields: {
          'GE_DEFAULTCOLOR': theme
        },
        match: {
          'RECNO': recno
        }
      })

      localStorage.setItem('defaultColor', this.theme);

      Dialog.create({
        title: 'Fature Catálogo',
        message: 'Tema alterado com sucesso!'
      })
    },
    async getCategorys() {
      const { data } = await supabase
        .storage
        .from('catalogo')
        .list();

      this.categorys = data;

      // console.log(data);
      // console.log(this.activedCategories);


      this.disabledCategories = await this.categorys.filter(el => {
        if(this.activedCategories.indexOf(el.name) === -1)
          return el;
      })
    },
    onAddLeague(league) {
      this.activedCategories.push(league);

      let idx;
      this.disabledCategories.map((item, index) => {
        if(item.name === league)
          idx = index;
      });

      this.disabledCategories.splice(idx, 1);
    },
    onRemoveLeague(league) {
      this.disabledCategories.push({
        name: league
      });

      let idx;
      this.activedCategories.map((item, index) => {
        if(item === league)
          idx = index;
      });

      this.activedCategories.splice(idx, 1);
    },
    async onSaveChooseLeagues() {
      const { data, error } = await supabase
        .from('GE_SETTINGS')
        .update({
          GE_CATEGORYS: this.activedCategories
        })
        .match({
          RECNO: this.recno
        })
      
      if(data) {
        Dialog.create({
          title: 'Fature Catálogo',
          message: 'Alterações salvas com sucesso!',
          cancel: 'OK',
          ok: false
        })
      } else {
        console.error('Erro atualizar Ligas', error.message);
      }
    },
    onEditRow(_, row) {
      this.comprovante = false
      this.editRow = row

      this.client = row.client
      this.phone = row.phone
      this.issueDate = row.dt
      this.status = row.status
      this.items = row.items
      this.address = row.address
      this.recnoTable = row.id
      this.observ = row.obs

      this.dataEmissao = DateFormat(String(row.dt).replaceAll('-', ''))
      this.modalPreSale = true;
    },
    onCancel() {
      Dialog.create({
        title: 'Gestão Esportes',
        message: 'Deseja realmente sair? Ao sair o preenchimento será perdido.',
        persistent: true,
        cancel: true,
      }).onOk(() => {
        this.modalPreSale = false;
      })
    },
    async onDelete() {
      Dialog.create({
        title: 'Gestão Esportes',
        message: 'Tem certeza que deseja excluir o orçamento?',
        cancel: 'Não',
        ok: 'Sim'
      })
        .onCancel(() => { return null })
        .onOk(async () => {
          const { data } = await supabase
            .from('GE_PRE_SALE')
            .update({'DELETED': '*'})
            .match({'RECNO': this.editRow.recno})
    
          if(data) {
            Dialog.create({
              title: 'Gestão Esportes',
              message: 'Orçamento excluído com sucesso!',
              cancel: false,
              ok: true
            }).onOk(() => {
              this.modalPreSale = false;
              this.executeQuery();
              return '';
            })
          }
        })
    },
    async onSave() {
      if(this.comprovante) {
        this.status = 'ENVIADO AO CLIENTE'
      }

      try {
        await this.updateData({
          table: 'GE_PRE_SALE',
          fields: {
            'GE_ADDRESS': this.address,
            'GE_CLIENT': this.client,
            'GE_ITEMS': this.editRow.items,
            'GE_PHONE': this.phone,
            'GE_STATUS': this.status,
            'GE_DATE_ISSUE': this.issueDate,
            'GE_OBS': this.observ,
          },
          match: {
            'RECNO': this.editRow.recno
          }
        })

        Dialog.create({
          title: 'Gestão Esportes',
          message: 'Orçamento alterado com sucesso!'
        });

        this.modalPreSale = false;
        this.executeQuery();
      } catch (error) {
        Dialog.create({
          title: 'Gestão Esportes',
          message: error.message
        });
      }
    },
    onOpenComprovante() {
      this.modalComprovante = true

      let priceTotal = 0
      this.editRow.items.forEach(row => {
        priceTotal += parseFloat(row.price)
      });

      this.totalGeral = parseFloat(priceTotal).toFixed(2);
    },
    async executeQuery() {
      let enterprise = localStorage.getItem('enterprise');

      let sett = await this.getDataWithoutFilters({
        table: 'GE_SETTINGS',
        fields: '*',
        enterprise: enterprise
      })

      console.log(sett)

      this.activedCategories = sett[0].GE_CATEGORYS
      this.recno = sett[0].RECNO
      this.numWhats = sett[0].GE_NUMWHATS
      this.msgWhats = sett[0].GE_MSGWHATS
      this.optCatalog = sett[0].GE_PRICEINCATALOG === 'with' ? 'com' : 'sem'
      this.perfilCatalogDB = sett[0].GE_PRICEINCATALOG

      if(sett) {
        null;
      } else {
        console.error(sett.error.message);
      }

      const slug = Slug(enterprise);
      const url = String(window.location.href).replace('/settings', `/${slug}`);

      this.linkCatalogo = url;

      //~> PARA RETORNAR DADOS DA TABELA
      const { data, error } = await supabase
        .from('GE_PRE_SALE')
        .select('*')
        .filter('GE_ENTERPRISE', 'eq', Slug(this.enterprise))
        .eq('DELETED', ' ')

      if(error) { console.error(error.message); }
      if(data) {
        this.rows = data.map((pre) => {
          return {
            dt: pre.GE_DATE_ISSUE,
            recno: pre.RECNO,
            teamHearth: pre.GE_TEAM_HEARTH,
            status: pre.GE_STATUS,
            phone: pre.GE_PHONE,
            items: pre.GE_ITEMS,
            client: pre.GE_CLIENT,
            address: pre.GE_ADDRESS,
            obs: pre.GE_OBS,
          }
        });
      }
    },
    async onSaveComprovante() {
      Loading.show({
        title: 'Fature Catálogo',
        message: 'Gerando Comprovante, aguarde...',
        spinner: QSpinnerHourglass
      });

      htmlToImage.toPng(
        document.querySelector('.orcamento'),
        { quality: 1 }
      ).then(dataURL => {
        var link = document.createElement('a');
        link.download = 'orcamento_produtos.png';
        link.href = dataURL;
        link.click();
      })




      // const status = exportFile(
      //   `orçamento (${this.client})`,
      //   this.$refs.htmlPDF,
      //   {
      //     // encoding: 'utf-8',
      //     mimeType: 'application/pdf'
      //     // mimeType: 'image/png'
      //   }
      // )

      // console.log(status);




      Loading.hide();
    },
    async onRegisterWhats() {
      if(this.numWhats === null) {
        Dialog.create({
          title: 'Fature Catálogo',
          message: 'Favor preencher o número de Whatsapp conforme regras destacadas!'
        })

        return
      }

      if(this.msgWhats === null || this.msgWhats === '') {
        Dialog.create({
          title: 'Fature Catálogo',
          message: 'Favor preencher o Texto Padrão com uma mensagem a receber ao iniciar um diálogo!'
        })

        return
      }



      const { data, error } = await supabase
        .from('GE_SETTINGS')
        .update({
          GE_NUMWHATS: this.numWhats,
          GE_MSGWHATS: this.msgWhats
        })
        .match({
          GE_ENTERPRISE: this.enterprise
        })

      if(data) {
        Dialog.create({
          title: 'Fature Catálogo',
          message: 'Whatsapp cadastrado com sucesso!'
        })
      }
      if(error) {
        Dialog.create({
          title: 'Fature Catálogo',
          message: 'Não foi possivel gravar o Whatsapp, repita o processo. Se persistir o problema entre em contato com os administradores!'
        })
      }
    },
    async onCopyLinkCatalogo() {
      await navigator.clipboard.writeText(this.linkCatalogo)
    },
  },
  async created() {
    defaultColor();
    this.executeQuery();
    this.getCategorys();

    if(this.msgWhats === '' || this.msgWhats === null || this.msgWhats === undefined) {
      this.msgWhats = `Olá, gostaria de mais detalhes sobre os produtos da loja ${this.enterprise}!`
    }

  }
}
</script>

<style lang="scss">
  @import url('../../globais/GlobalStyles.scss');

  .styleChipDisabled {
    width: 200px;
    /* background-color: rgba(red, $alpha: 0.2); */
    border: 2px solid red;
    border-radius: 0px 20px 20px 0px;
    color: black;
    margin-top: 5px;
    text-align: left;
    font-weight: bold;
    padding-left: 5px;
    padding-right: 1px;
  }
  .styleChipActived {
    width: 200px;
    /* background-color: rgba(green, $alpha: 0.3); */
    border: 2px solid green;
    border-radius: 20px 0px 0px 20px;
    color: black;
    margin-top: 5px;
    text-align: right;
    font-weight: bold;
    padding-left: 1px;
    padding-right: 5px;
  }

  .halfScreen {
    width: 50%;
  }

  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button {
    appearance: none;
    -webkit-appearance: none;
    margin: 0;
  }

  input[type="number"] {
    appearance: textfield;
      -moz-appearance: textfield;
  }

  .orcamento {
    /* height: 297mm; */
    height: auto;
    width: 210mm;
  }

  .imgCard {
    border-radius: 8px;
  }

  /* Celulares (portrait tablets and large phones, 600px and up) */
  @media only screen and (max-width: 600px) {
    .containerLeague {
      flex-direction: column;
      align-items: center;
    }

    .halfScreen {
      width: 100%;
      margin-top: 20px;
    }
  }

</style>