
export default function removeExtensionFile(fileName) {
  var newName;
  var extensions = [
    '.png', '.jpg', '.jpeg', '.gif', '.bmp', '.tiff',
    '.eps', '.svg', '.avif', '.jpe', '.jif', '.jfi',
    '.jfif', '.raw', '.webp'
  ]

  extensions.forEach(ext => {
    if(String(fileName).includes(ext)) {
      newName = {
        name: String(fileName).replace(ext, ''),
        extension: ext
      }
    }
  });

  return newName;
}